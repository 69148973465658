
<template>
    <div class="position-relative d-flex" :style="{ 'left': wave1_distance + 'px' }" style="width: 350vw;">
        <svg id="wave" style="transform:rotate(0deg); transition: 0.3s" viewBox="0 0 1440 330" version="1.1"
            xmlns="http://www.w3.org/2000/svg">
            <defs>
                <linearGradient id="sw-gradient-8" x1="0" x2="0" y1="1" y2="0">
                    <stop stop-color="rgba(97, 97, 216, 1)" offset="0%"></stop>
                    <stop stop-color="rgba(91, 139, 218, 1)" offset="100%"></stop>
                </linearGradient>
            </defs>
            <path style="transform:translate(0, 0px); opacity:1" fill="url(#sw-gradient-8)"
                d="M0,198L10,170.5C20,143,40,88,60,82.5C80,77,100,121,120,143C140,165,160,165,180,143C200,121,220,77,240,49.5C260,22,280,11,300,44C320,77,340,154,360,192.5C380,231,400,231,420,198C440,165,460,99,480,82.5C500,66,520,99,540,93.5C560,88,580,44,600,27.5C620,11,640,22,660,22C680,22,700,11,720,33C740,55,760,110,780,132C800,154,820,143,840,159.5C860,176,880,220,900,214.5C920,209,940,154,960,154C980,154,1000,209,1020,209C1040,209,1060,154,1080,110C1100,66,1120,33,1140,22C1160,11,1180,22,1200,44C1220,66,1240,99,1260,126.5C1280,154,1300,176,1320,154C1340,132,1360,66,1380,49.5C1400,33,1420,66,1430,82.5L1440,99L1440,330L1430,330C1420,330,1400,330,1380,330C1360,330,1340,330,1320,330C1300,330,1280,330,1260,330C1240,330,1220,330,1200,330C1180,330,1160,330,1140,330C1120,330,1100,330,1080,330C1060,330,1040,330,1020,330C1000,330,980,330,960,330C940,330,920,330,900,330C880,330,860,330,840,330C820,330,800,330,780,330C760,330,740,330,720,330C700,330,680,330,660,330C640,330,620,330,600,330C580,330,560,330,540,330C520,330,500,330,480,330C460,330,440,330,420,330C400,330,380,330,360,330C340,330,320,330,300,330C280,330,260,330,240,330C220,330,200,330,180,330C160,330,140,330,120,330C100,330,80,330,60,330C40,330,20,330,10,330L0,330Z">
            </path>
        </svg>

        <svg id="wave" style="transform:rotate(0deg); transition: 0.3s;position: relative;left: -20px;"
            viewBox="0 0 1440 330" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <defs>
                <linearGradient id="sw-gradient-8" x1="0" x2="0" y1="1" y2="0">
                    <stop stop-color="rgba(97, 97, 216, 1)" offset="0%"></stop>
                    <stop stop-color="rgba(91, 139, 218, 1)" offset="100%"></stop>
                </linearGradient>
            </defs>
            <path style="transform:translate(0, 0px); opacity:1" fill="url(#sw-gradient-8)"
                d="M0,33L10,44C20,55,40,77,60,77C80,77,100,55,120,66C140,77,160,121,180,132C200,143,220,121,240,132C260,143,280,187,300,192.5C320,198,340,165,360,126.5C380,88,400,44,420,22C440,0,460,0,480,5.5C500,11,520,22,540,71.5C560,121,580,209,600,236.5C620,264,640,231,660,181.5C680,132,700,66,720,66C740,66,760,132,780,165C800,198,820,198,840,181.5C860,165,880,132,900,132C920,132,940,165,960,176C980,187,1000,176,1020,176C1040,176,1060,187,1080,181.5C1100,176,1120,154,1140,154C1160,154,1180,176,1200,187C1220,198,1240,198,1260,203.5C1280,209,1300,220,1320,209C1340,198,1360,165,1380,126.5C1400,88,1420,44,1430,22L1440,0L1440,330L1430,330C1420,330,1400,330,1380,330C1360,330,1340,330,1320,330C1300,330,1280,330,1260,330C1240,330,1220,330,1200,330C1180,330,1160,330,1140,330C1120,330,1100,330,1080,330C1060,330,1040,330,1020,330C1000,330,980,330,960,330C940,330,920,330,900,330C880,330,860,330,840,330C820,330,800,330,780,330C760,330,740,330,720,330C700,330,680,330,660,330C640,330,620,330,600,330C580,330,560,330,540,330C520,330,500,330,480,330C460,330,440,330,420,330C400,330,380,330,360,330C340,330,320,330,300,330C280,330,260,330,240,330C220,330,200,330,180,330C160,330,140,330,120,330C100,330,80,330,60,330C40,330,20,330,10,330L0,330Z">
            </path>
        </svg>
        <svg id="wave" style="transform:rotate(0deg); transition: 0.3s;position: relative;left: -20px;"
            viewBox="0 0 1440 330" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <defs>
                <linearGradient id="sw-gradient-8" x1="0" x2="0" y1="1" y2="0">
                    <stop stop-color="rgba(97, 97, 216, 1)" offset="0%"></stop>
                    <stop stop-color="rgba(91, 139, 218, 1)" offset="100%"></stop>
                </linearGradient>
            </defs>
            <path style="transform:translate(0, 0px); opacity:1" fill="url(#sw-gradient-8)"
                d="M0,0L10,49.5C20,99,40,198,60,231C80,264,100,231,120,203.5C140,176,160,154,180,143C200,132,220,132,240,126.5C260,121,280,110,300,110C320,110,340,121,360,132C380,143,400,154,420,143C440,132,460,99,480,77C500,55,520,44,540,33C560,22,580,11,600,44C620,77,640,154,660,159.5C680,165,700,99,720,60.5C740,22,760,11,780,55C800,99,820,198,840,236.5C860,275,880,253,900,203.5C920,154,940,77,960,60.5C980,44,1000,88,1020,110C1040,132,1060,132,1080,132C1100,132,1120,132,1140,154C1160,176,1180,220,1200,209C1220,198,1240,132,1260,88C1280,44,1300,22,1320,16.5C1340,11,1360,22,1380,38.5C1400,55,1420,77,1430,88L1440,99L1440,330L1430,330C1420,330,1400,330,1380,330C1360,330,1340,330,1320,330C1300,330,1280,330,1260,330C1240,330,1220,330,1200,330C1180,330,1160,330,1140,330C1120,330,1100,330,1080,330C1060,330,1040,330,1020,330C1000,330,980,330,960,330C940,330,920,330,900,330C880,330,860,330,840,330C820,330,800,330,780,330C760,330,740,330,720,330C700,330,680,330,660,330C640,330,620,330,600,330C580,330,560,330,540,330C520,330,500,330,480,330C460,330,440,330,420,330C400,330,380,330,360,330C340,330,320,330,300,330C280,330,260,330,240,330C220,330,200,330,180,330C160,330,140,330,120,330C100,330,80,330,60,330C40,330,20,330,10,330L0,330Z">
            </path>
        </svg>
    </div>
</template>
<script>
export default {
    mounted() {
        let that = this
        setInterval(function () {
            that.wave1_distance -= 15;
            if (that.wave1_distance < -(window.innerWidth * 3 - 350)) {
                that.wave1_number++;

                that.wave1_distance = 0
            }
        }, 1000);
    },
    data() {
        return {
            wave1_distance: 0,
            wave1_number: 1
        }
    }
}
</script>