<template>
    <div class="position-relative d-flex" :style="{ 'left': wave1_distance + 'px' }" style="width: 800vw;">

        <svg id="wave" style="transform:rotate(0deg); transition: 0.3s" viewBox="0 0 1440 140" version="1.1"
            xmlns="http://www.w3.org/2000/svg">
            <defs>
                <linearGradient id="sw-gradient-0" x1="0" x2="0" y1="1" y2="0">
                    <stop stop-color="rgba(188, 130, 170, 1)" offset="0%"></stop>
                    <stop stop-color="rgba(134, 100, 198, 1)" offset="100%"></stop>
                </linearGradient>
            </defs>
            <path style="transform:translate(0, 0px); opacity:1" fill="url(#sw-gradient-0)"
                d="M0,98L10,84C20,70,40,42,60,35C80,28,100,42,120,58.3C140,75,160,93,180,84C200,75,220,37,240,37.3C260,37,280,75,300,84C320,93,340,75,360,63C380,51,400,47,420,37.3C440,28,460,14,480,23.3C500,33,520,65,540,77C560,89,580,79,600,70C620,61,640,51,660,44.3C680,37,700,33,720,37.3C740,42,760,56,780,53.7C800,51,820,33,840,21C860,9,880,5,900,16.3C920,28,940,56,960,67.7C980,79,1000,75,1020,72.3C1040,70,1060,70,1080,63C1100,56,1120,42,1140,44.3C1160,47,1180,65,1200,72.3C1220,79,1240,75,1260,60.7C1280,47,1300,23,1320,21C1340,19,1360,37,1380,49C1400,61,1420,65,1430,67.7L1440,70L1440,140L1430,140C1420,140,1400,140,1380,140C1360,140,1340,140,1320,140C1300,140,1280,140,1260,140C1240,140,1220,140,1200,140C1180,140,1160,140,1140,140C1120,140,1100,140,1080,140C1060,140,1040,140,1020,140C1000,140,980,140,960,140C940,140,920,140,900,140C880,140,860,140,840,140C820,140,800,140,780,140C760,140,740,140,720,140C700,140,680,140,660,140C640,140,620,140,600,140C580,140,560,140,540,140C520,140,500,140,480,140C460,140,440,140,420,140C400,140,380,140,360,140C340,140,320,140,300,140C280,140,260,140,240,140C220,140,200,140,180,140C160,140,140,140,120,140C100,140,80,140,60,140C40,140,20,140,10,140L0,140Z">
            </path>
        </svg>
        <svg id="wave" style="transform:rotate(0deg); transition: 0.3s" viewBox="0 0 1440 140" version="1.1"
            xmlns="http://www.w3.org/2000/svg">
            <defs>
                <linearGradient id="sw-gradient-1" x1="0" x2="0" y1="1" y2="0">
                    <stop stop-color="rgba(188, 130, 170, 1)" offset="0%"></stop>
                    <stop stop-color="rgba(134, 100, 198, 1)" offset="100%"></stop>
                </linearGradient>
            </defs>
            <path style="transform:translate(0, 0px); opacity:1" fill="url(#sw-gradient-1)"
                d="M0,14L10,25.7C20,37,40,61,60,72.3C80,84,100,84,120,77C140,70,160,56,180,51.3C200,47,220,51,240,65.3C260,79,280,103,300,107.3C320,112,340,98,360,91C380,84,400,84,420,77C440,70,460,56,480,56C500,56,520,70,540,81.7C560,93,580,103,600,88.7C620,75,640,37,660,18.7C680,0,700,0,720,4.7C740,9,760,19,780,23.3C800,28,820,28,840,30.3C860,33,880,37,900,42C920,47,940,51,960,56C980,61,1000,65,1020,58.3C1040,51,1060,33,1080,37.3C1100,42,1120,70,1140,84C1160,98,1180,98,1200,100.3C1220,103,1240,107,1260,98C1280,89,1300,65,1320,63C1340,61,1360,79,1380,81.7C1400,84,1420,70,1430,63L1440,56L1440,140L1430,140C1420,140,1400,140,1380,140C1360,140,1340,140,1320,140C1300,140,1280,140,1260,140C1240,140,1220,140,1200,140C1180,140,1160,140,1140,140C1120,140,1100,140,1080,140C1060,140,1040,140,1020,140C1000,140,980,140,960,140C940,140,920,140,900,140C880,140,860,140,840,140C820,140,800,140,780,140C760,140,740,140,720,140C700,140,680,140,660,140C640,140,620,140,600,140C580,140,560,140,540,140C520,140,500,140,480,140C460,140,440,140,420,140C400,140,380,140,360,140C340,140,320,140,300,140C280,140,260,140,240,140C220,140,200,140,180,140C160,140,140,140,120,140C100,140,80,140,60,140C40,140,20,140,10,140L0,140Z">
            </path>
        </svg>
        <svg id="wave" style="transform:rotate(0deg); transition: 0.3s" viewBox="0 0 1440 140" version="1.1"
            xmlns="http://www.w3.org/2000/svg">
            <defs>
                <linearGradient id="sw-gradient-2" x1="0" x2="0" y1="1" y2="0">
                    <stop stop-color="rgba(188, 130, 170, 1)" offset="0%"></stop>
                    <stop stop-color="rgba(134, 100, 198, 1)" offset="100%"></stop>
                </linearGradient>
            </defs>
            <path style="transform:translate(0, 0px); opacity:1" fill="url(#sw-gradient-2)"
                d="M0,14L10,32.7C20,51,40,89,60,98C80,107,100,89,120,70C140,51,160,33,180,42C200,51,220,89,240,95.7C260,103,280,79,300,65.3C320,51,340,47,360,51.3C380,56,400,70,420,84C440,98,460,112,480,114.3C500,117,520,107,540,98C560,89,580,79,600,77C620,75,640,79,660,70C680,61,700,37,720,23.3C740,9,760,5,780,4.7C800,5,820,9,840,16.3C860,23,880,33,900,30.3C920,28,940,14,960,9.3C980,5,1000,9,1020,28C1040,47,1060,79,1080,79.3C1100,79,1120,47,1140,39.7C1160,33,1180,51,1200,65.3C1220,79,1240,89,1260,79.3C1280,70,1300,42,1320,30.3C1340,19,1360,23,1380,21C1400,19,1420,9,1430,4.7L1440,0L1440,140L1430,140C1420,140,1400,140,1380,140C1360,140,1340,140,1320,140C1300,140,1280,140,1260,140C1240,140,1220,140,1200,140C1180,140,1160,140,1140,140C1120,140,1100,140,1080,140C1060,140,1040,140,1020,140C1000,140,980,140,960,140C940,140,920,140,900,140C880,140,860,140,840,140C820,140,800,140,780,140C760,140,740,140,720,140C700,140,680,140,660,140C640,140,620,140,600,140C580,140,560,140,540,140C520,140,500,140,480,140C460,140,440,140,420,140C400,140,380,140,360,140C340,140,320,140,300,140C280,140,260,140,240,140C220,140,200,140,180,140C160,140,140,140,120,140C100,140,80,140,60,140C40,140,20,140,10,140L0,140Z">
            </path>
        </svg>
    </div>
</template>
<script>
export default {
    mounted() {
        let that = this
        setInterval(function () {
            that.wave1_distance -= 15;
            if (that.wave1_distance < -(window.innerWidth * 3 - 400)) {
                that.wave1_number++;

                that.wave1_distance = 0
            }
        }, 1000);
    },
    data() {
        return {
            wave1_distance: 0,
            wave1_number: 1
        }
    }
}
</script>