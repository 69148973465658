
<template>
    <div class="position-relative d-flex" :style="{ 'left': wave1_distance + 'px' }" style="width: 350vw;">
        <svg id="wave" style="transform:rotate(0deg); transition: 0.3s" viewBox="0 0 1440 330" version="1.1"
            xmlns="http://www.w3.org/2000/svg">
            <defs>
                <linearGradient id="sw-gradient-7" x1="0" x2="0" y1="1" y2="0">
                    <stop stop-color="rgba(188, 130, 170, 1)" offset="0%"></stop>
                    <stop stop-color="rgba(134, 100, 198, 1)" offset="100%"></stop>
                </linearGradient>
            </defs>
            <path style="transform:translate(0, 0px); opacity:1" fill="url(#sw-gradient-7)"
                d="M0,297L10,264C20,231,40,165,60,132C80,99,100,99,120,115.5C140,132,160,165,180,181.5C200,198,220,198,240,192.5C260,187,280,176,300,176C320,176,340,187,360,203.5C380,220,400,242,420,242C440,242,460,220,480,214.5C500,209,520,220,540,231C560,242,580,253,600,214.5C620,176,640,88,660,49.5C680,11,700,22,720,71.5C740,121,760,209,780,253C800,297,820,297,840,264C860,231,880,165,900,121C920,77,940,55,960,44C980,33,1000,33,1020,49.5C1040,66,1060,99,1080,143C1100,187,1120,242,1140,231C1160,220,1180,143,1200,115.5C1220,88,1240,110,1260,143C1280,176,1300,220,1320,209C1340,198,1360,132,1380,104.5C1400,77,1420,88,1430,93.5L1440,99L1440,330L1430,330C1420,330,1400,330,1380,330C1360,330,1340,330,1320,330C1300,330,1280,330,1260,330C1240,330,1220,330,1200,330C1180,330,1160,330,1140,330C1120,330,1100,330,1080,330C1060,330,1040,330,1020,330C1000,330,980,330,960,330C940,330,920,330,900,330C880,330,860,330,840,330C820,330,800,330,780,330C760,330,740,330,720,330C700,330,680,330,660,330C640,330,620,330,600,330C580,330,560,330,540,330C520,330,500,330,480,330C460,330,440,330,420,330C400,330,380,330,360,330C340,330,320,330,300,330C280,330,260,330,240,330C220,330,200,330,180,330C160,330,140,330,120,330C100,330,80,330,60,330C40,330,20,330,10,330L0,330Z">
            </path>
        </svg>

        <svg id="wave" style="transform:rotate(0deg); transition: 0.3s;position: relative;left: -30px;"
            viewBox="0 0 1440 330" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <defs>
                <linearGradient id="sw-gradient-7" x1="0" x2="0" y1="1" y2="0">
                    <stop stop-color="rgba(188, 130, 170, 1)" offset="0%"></stop>
                    <stop stop-color="rgba(134, 100, 198, 1)" offset="100%"></stop>
                </linearGradient>
            </defs>
            <path style="transform:translate(0, 0px); opacity:1" fill="url(#sw-gradient-7)"
                d="M0,264L10,264C20,264,40,264,60,236.5C80,209,100,154,120,121C140,88,160,77,180,82.5C200,88,220,110,240,104.5C260,99,280,66,300,71.5C320,77,340,121,360,159.5C380,198,400,231,420,220C440,209,460,154,480,126.5C500,99,520,99,540,115.5C560,132,580,165,600,176C620,187,640,176,660,176C680,176,700,187,720,192.5C740,198,760,198,780,203.5C800,209,820,220,840,187C860,154,880,77,900,82.5C920,88,940,176,960,220C980,264,1000,264,1020,247.5C1040,231,1060,198,1080,159.5C1100,121,1120,77,1140,88C1160,99,1180,165,1200,198C1220,231,1240,231,1260,225.5C1280,220,1300,209,1320,192.5C1340,176,1360,154,1380,170.5C1400,187,1420,242,1430,269.5L1440,297L1440,330L1430,330C1420,330,1400,330,1380,330C1360,330,1340,330,1320,330C1300,330,1280,330,1260,330C1240,330,1220,330,1200,330C1180,330,1160,330,1140,330C1120,330,1100,330,1080,330C1060,330,1040,330,1020,330C1000,330,980,330,960,330C940,330,920,330,900,330C880,330,860,330,840,330C820,330,800,330,780,330C760,330,740,330,720,330C700,330,680,330,660,330C640,330,620,330,600,330C580,330,560,330,540,330C520,330,500,330,480,330C460,330,440,330,420,330C400,330,380,330,360,330C340,330,320,330,300,330C280,330,260,330,240,330C220,330,200,330,180,330C160,330,140,330,120,330C100,330,80,330,60,330C40,330,20,330,10,330L0,330Z">
            </path>
        </svg>
        <svg id="wave" style="transform:rotate(0deg); transition: 0.3s;position: relative;left: -35px;"
            viewBox="0 0 1440 330" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <defs>
                <linearGradient id="sw-gradient-7" x1="0" x2="0" y1="1" y2="0">
                    <stop stop-color="rgba(188, 130, 170, 1)" offset="0%"></stop>
                    <stop stop-color="rgba(134, 100, 198, 1)" offset="100%"></stop>
                </linearGradient>
            </defs>
            <path style="transform:translate(0, 0px); opacity:1" fill="url(#sw-gradient-7)"
                d="M0,264L10,253C20,242,40,220,60,176C80,132,100,66,120,38.5C140,11,160,22,180,33C200,44,220,55,240,93.5C260,132,280,198,300,236.5C320,275,340,286,360,269.5C380,253,400,209,420,170.5C440,132,460,99,480,104.5C500,110,520,154,540,192.5C560,231,580,264,600,247.5C620,231,640,165,660,148.5C680,132,700,165,720,181.5C740,198,760,198,780,214.5C800,231,820,264,840,253C860,242,880,187,900,159.5C920,132,940,132,960,143C980,154,1000,176,1020,176C1040,176,1060,154,1080,126.5C1100,99,1120,66,1140,49.5C1160,33,1180,33,1200,71.5C1220,110,1240,187,1260,198C1280,209,1300,154,1320,121C1340,88,1360,77,1380,77C1400,77,1420,88,1430,93.5L1440,99L1440,330L1430,330C1420,330,1400,330,1380,330C1360,330,1340,330,1320,330C1300,330,1280,330,1260,330C1240,330,1220,330,1200,330C1180,330,1160,330,1140,330C1120,330,1100,330,1080,330C1060,330,1040,330,1020,330C1000,330,980,330,960,330C940,330,920,330,900,330C880,330,860,330,840,330C820,330,800,330,780,330C760,330,740,330,720,330C700,330,680,330,660,330C640,330,620,330,600,330C580,330,560,330,540,330C520,330,500,330,480,330C460,330,440,330,420,330C400,330,380,330,360,330C340,330,320,330,300,330C280,330,260,330,240,330C220,330,200,330,180,330C160,330,140,330,120,330C100,330,80,330,60,330C40,330,20,330,10,330L0,330Z">
            </path>
        </svg>
    </div>
</template>
<script>
export default {
    mounted() {
        let that = this
        setInterval(function () {
            that.wave1_distance -= 15;
            if (that.wave1_distance < -(window.innerWidth * 3 - 350)) {
                that.wave1_number++;

                that.wave1_distance = 0
            }
        }, 1000);
    },
    data() {
        return {
            wave1_distance: 0,
            wave1_number: 1
        }
    }
}
</script>