<template>
    <div class="toast-box rounded-3 px-3 py-2" v-if="message">

        <div class="p-3 text-center rounded-3">
            <font-awesome-icon v-if="message.type == 'success'" class="icon mb-1" size="2xl" :icon="['fas', 'fa-check']" />
            <p class="mb-0 fw-400 fs-14">{{ message.message }}</p>
        </div>
    </div>
</template>
<script>
export default {
    props: ['message']
}
</script>
<style scoped>
.toast-box {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    color: white;
}

.toast-box>div {
    background-color: rgba(0, 0, 0, 0.8);
    display: inline;
    width: 40% !important;
}
</style>