
<template>
    <div class="position-relative d-flex" :style="{ 'left': wave1_distance + 'px' }" style="width: 350vw;">
        <svg id="wave" style="transform:rotate(0deg); transition: 0.3s" viewBox="0 0 1440 330" version="1.1"
            xmlns="http://www.w3.org/2000/svg">
            <defs>
                <linearGradient id="sw-gradient-4" x1="0" x2="0" y1="1" y2="0">
                    <stop stop-color="rgba(97, 97, 216, 1)" offset="0%"></stop>
                    <stop stop-color="rgba(91, 139, 218, 1)" offset="100%"></stop>
                </linearGradient>
            </defs>
            <path style="transform:translate(0, 0px); opacity:1" fill="url(#sw-gradient-4)"
                d="M0,33L10,55C20,77,40,121,60,115.5C80,110,100,55,120,60.5C140,66,160,132,180,176C200,220,220,242,240,247.5C260,253,280,242,300,220C320,198,340,165,360,126.5C380,88,400,44,420,71.5C440,99,460,198,480,198C500,198,520,99,540,55C560,11,580,22,600,38.5C620,55,640,77,660,93.5C680,110,700,121,720,110C740,99,760,66,780,55C800,44,820,55,840,60.5C860,66,880,66,900,71.5C920,77,940,88,960,93.5C980,99,1000,99,1020,110C1040,121,1060,143,1080,159.5C1100,176,1120,187,1140,203.5C1160,220,1180,242,1200,258.5C1220,275,1240,286,1260,258.5C1280,231,1300,165,1320,154C1340,143,1360,187,1380,220C1400,253,1420,275,1430,286L1440,297L1440,330L1430,330C1420,330,1400,330,1380,330C1360,330,1340,330,1320,330C1300,330,1280,330,1260,330C1240,330,1220,330,1200,330C1180,330,1160,330,1140,330C1120,330,1100,330,1080,330C1060,330,1040,330,1020,330C1000,330,980,330,960,330C940,330,920,330,900,330C880,330,860,330,840,330C820,330,800,330,780,330C760,330,740,330,720,330C700,330,680,330,660,330C640,330,620,330,600,330C580,330,560,330,540,330C520,330,500,330,480,330C460,330,440,330,420,330C400,330,380,330,360,330C340,330,320,330,300,330C280,330,260,330,240,330C220,330,200,330,180,330C160,330,140,330,120,330C100,330,80,330,60,330C40,330,20,330,10,330L0,330Z">
            </path>
        </svg>

        <svg id="wave" style="transform:rotate(0deg); transition: 0.3s;position: relative;left: -30px;"
            viewBox="0 0 1440 330" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <defs>
                <linearGradient id="sw-gradient-4" x1="0" x2="0" y1="1" y2="0">
                    <stop stop-color="rgba(97, 97, 216, 1)" offset="0%"></stop>
                    <stop stop-color="rgba(91, 139, 218, 1)" offset="100%"></stop>
                </linearGradient>
            </defs>
            <path style="transform:translate(0, 0px); opacity:1" fill="url(#sw-gradient-4)"
                d="M0,264L10,264C20,264,40,264,60,236.5C80,209,100,154,120,121C140,88,160,77,180,82.5C200,88,220,110,240,104.5C260,99,280,66,300,71.5C320,77,340,121,360,159.5C380,198,400,231,420,220C440,209,460,154,480,126.5C500,99,520,99,540,115.5C560,132,580,165,600,176C620,187,640,176,660,176C680,176,700,187,720,192.5C740,198,760,198,780,203.5C800,209,820,220,840,187C860,154,880,77,900,82.5C920,88,940,176,960,220C980,264,1000,264,1020,247.5C1040,231,1060,198,1080,159.5C1100,121,1120,77,1140,88C1160,99,1180,165,1200,198C1220,231,1240,231,1260,225.5C1280,220,1300,209,1320,192.5C1340,176,1360,154,1380,170.5C1400,187,1420,242,1430,269.5L1440,297L1440,330L1430,330C1420,330,1400,330,1380,330C1360,330,1340,330,1320,330C1300,330,1280,330,1260,330C1240,330,1220,330,1200,330C1180,330,1160,330,1140,330C1120,330,1100,330,1080,330C1060,330,1040,330,1020,330C1000,330,980,330,960,330C940,330,920,330,900,330C880,330,860,330,840,330C820,330,800,330,780,330C760,330,740,330,720,330C700,330,680,330,660,330C640,330,620,330,600,330C580,330,560,330,540,330C520,330,500,330,480,330C460,330,440,330,420,330C400,330,380,330,360,330C340,330,320,330,300,330C280,330,260,330,240,330C220,330,200,330,180,330C160,330,140,330,120,330C100,330,80,330,60,330C40,330,20,330,10,330L0,330Z">
            </path>
        </svg>
        <svg id="wave" style="transform:rotate(0deg); transition: 0.3s;position: relative;left: -30px;"
            viewBox="0 0 1440 330" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <defs>
                <linearGradient id="sw-gradient-5" x1="0" x2="0" y1="1" y2="0">
                    <stop stop-color="rgba(97, 97, 216, 1)" offset="0%"></stop>
                    <stop stop-color="rgba(91, 139, 218, 1)" offset="100%"></stop>
                </linearGradient>
            </defs>
            <path style="transform:translate(0, 0px); opacity:1" fill="url(#sw-gradient-5)"
                d="M0,264L10,253C20,242,40,220,60,176C80,132,100,66,120,38.5C140,11,160,22,180,33C200,44,220,55,240,93.5C260,132,280,198,300,236.5C320,275,340,286,360,269.5C380,253,400,209,420,170.5C440,132,460,99,480,104.5C500,110,520,154,540,192.5C560,231,580,264,600,247.5C620,231,640,165,660,148.5C680,132,700,165,720,181.5C740,198,760,198,780,214.5C800,231,820,264,840,253C860,242,880,187,900,159.5C920,132,940,132,960,143C980,154,1000,176,1020,176C1040,176,1060,154,1080,126.5C1100,99,1120,66,1140,49.5C1160,33,1180,33,1200,71.5C1220,110,1240,187,1260,198C1280,209,1300,154,1320,121C1340,88,1360,77,1380,77C1400,77,1420,88,1430,93.5L1440,99L1440,330L1430,330C1420,330,1400,330,1380,330C1360,330,1340,330,1320,330C1300,330,1280,330,1260,330C1240,330,1220,330,1200,330C1180,330,1160,330,1140,330C1120,330,1100,330,1080,330C1060,330,1040,330,1020,330C1000,330,980,330,960,330C940,330,920,330,900,330C880,330,860,330,840,330C820,330,800,330,780,330C760,330,740,330,720,330C700,330,680,330,660,330C640,330,620,330,600,330C580,330,560,330,540,330C520,330,500,330,480,330C460,330,440,330,420,330C400,330,380,330,360,330C340,330,320,330,300,330C280,330,260,330,240,330C220,330,200,330,180,330C160,330,140,330,120,330C100,330,80,330,60,330C40,330,20,330,10,330L0,330Z">
            </path>
        </svg>
    </div>
</template>
<script>
export default {
    mounted() {
        let that = this
        setInterval(function () {
            that.wave1_distance -= 15;
            if (that.wave1_distance < -(window.innerWidth * 3 - 350)) {
                that.wave1_number++;

                that.wave1_distance = 0
            }
        }, 1000);
    },
    data() {
        return {
            wave1_distance: 0,
            wave1_number: 1
        }
    }
}
</script>