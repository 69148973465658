import { createStore } from "vuex";
import { auth } from "./auth.module";
import { index } from "./index.module";
import { cash } from "./cash.module";



const store = createStore({
  modules: {
    auth,
    index,
    cash
  },
});

export default store;