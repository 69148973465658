<template>
    <div class="footer-nav bg-pri">
        <div class="row p-0 m-0 h-100">
            <router-link active-class="active" :to="{ name: nav.route }"
                class="col-3 p-0 d-flex flex-column text-decoration-none justify-content-center align-items-center"
                :key="index" v-for="(nav, index) in navs">
                <font-awesome-icon class="icon mb-1" size="lg" :icon="['fas', nav.icon]" />
                <small class="mb-0 fw-bold" style=" line-height: 1;font-size: .8rem;">{{ $t(nav.title) }}</small>
            </router-link>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            navs: [
                {
                    'title': 'home',
                    'icon': 'fa-home',
                    'route': 'home'
                },
                {
                    'title': 'withdraw',
                    'icon': 'fa-money-bill-transfer',
                    'route': 'withdraw'
                },
                {
                    'title': 'share',
                    'icon': 'fa-share-from-square',
                    'route': 'share'
                },
                {
                    'title': 'me',
                    'icon': 'fa-user',
                    'route': 'my'
                }
            ]
        }
    }
}
</script>
<style scoped>
.footer-nav a {
    height: 55px;
    color: #fff;
}

.footer-nav .active .icon {
    /* background-image: -webkit-linear-gradient(90deg, rgb(125, 80, 254), rgb(74, 183, 255));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */

    /* background-image: -webkit-linear-gradient(#48BDFE, #7F47FB) !important;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */

    color: #6f78fe;
}
</style>